body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (min-width: 992px) {
  .splash .sp-bx .btn {
    min-width: 138px !important;
    padding: 0.5em 1.5em;
  }
}

.col-xs-7 {
  width: 58.3333333333%;
}

.splash .sp-lk:link,
.splash .sp-lk:visited {
  color: #335075;
  text-decoration: none;
}

#bg {
  height: 200%;
  left: -50%;
  position: fixed;
  top: -50%;
  width: 200%;
}

@media (min-width: 992px) {
  .splash .sp-bx .btn {
    min-width: 138px;
    padding: 0.5em 1.5em;
  }
}

.mrgn-bttm-md {
  margin-bottom: 15px;
}
main {
  font-size: 20px;
  line-height: 1.65em;
}
.col-xs-5 {
  width: 41.6666666667%;
}

@media (min-width: 992px) {
  .col-md-8 {
    width: 66.6666666667%;
  }
}

@media (min-width: 992px) {
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9 {
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-4 {
    width: 33.3333333333%;
  }
}

.text-left {
  text-align: left;
}
@media (min-width: 992px) {
  .splash .sp-hb {
    margin: 175px auto;
    width: 500px;
  }
}

@media (min-width: 768px) {
  .splash .sp-hb {
    margin: 50px auto;
    width: 400px;
  }
}

#bg img {
  height: auto;
  max-width: 100%;
  bottom: 0;
  left: 0;
  margin: auto;
  min-height: 50%;
  min-width: 50%;
  position: absolute;
  right: 0;
  top: 0;
}

.splash .sp-bx {
  background-color: #f8f8f8;
  padding: 30px 30px 19px;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  float: left;
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1200px) {
  .splash .sp-bx-bt {
    background-color: #e1e4e7;
    padding: 30px 30px 13px;
  }
}

@media (min-width: 992px) {
  .splash .sp-bx-bt {
    background-color: #e1e4e7;
    padding: 30px 30px 13px;
  }
}

@media (min-width: 768px) {
  .splash .sp-bx-bt {
    background-color: #e1e4e7;
    padding: 30px;
  }
  .col-xs-12 {
    width: 100%;
  }
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

.text-right {
  text-align: right;
}

@media (min-width: 992px) {
  .splash .sp-hb {
    margin: 175px auto;
    width: 500px;
  }
}

p {
  margin: 0 0 11.5px;
}

@media (min-width: 992px) {
  .splash .sp-bx .btn {
    min-width: 138px;
    padding: 0.5em 1.5em;
  }
}

.splash .sp-bx .btn {
  -webkit-box-shadow: 0 4px #ddd;
  box-shadow: 0 4px #ddd;
  margin-bottom: 14px;
  margin-top: 35px;
  min-width: 110px;
}

.btn-primary:visited {
  color: #fff;
}

body .btn-primary {
  background-color: #26374a;
  border-color: #26374a;
}

a:visited {
  color: #7834bc;
}

.nav a,
a.btn {
  text-decoration: none;
}

.btn-primary {
  color: #fff;
  background-color: #2572b4;
  border-color: #143c5f;
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 10px 14px;
  font-size: 16px;
  line-height: 1.4375;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.splash .glyphicon {
  color: #9e9e9e;
  font-size: 5px;
  top: -3px;
}

main .glyphicon {
  top: 2px;
}
.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: "Glyphicons Halflings, sans-serif";
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.col-xs-11 {
  width: 91.6666666667%;
}

#mb-pnl .srch-pnl label,
#mb-pnl h3,
#wb-bc h2,
#wb-glb-mn h2,
#wb-info h2,
#wb-lng h2,
#wb-sec h2,
#wb-sm h2,
#wb-srch h2,
#wb-srch label,
.wb-calevt-cal .cal-days td ul,
.wb-fnote dt,
.wb-inv,
.wb-invisible,
.wb-show-onfocus,
.wb-sl {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.splash img {
  margin-bottom: 0.375em;
}
.splash img,
.splash object {
  height: auto;
  max-width: 100%;
}
img {
  vertical-align: middle;
  border: 0;
}

.mainContainer {
  padding: 1rem 4rem;
  background: white;
  min-height: 400px;
}

.flex {
  display: flex;
}

.pageContainer {
  justify-content: space-between;
  background: #ffffff;
  border: 1px #dddddd solid;
  opacity: 1;
  min-height: 300px;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 6px 10px var(--designer-shadow);
  padding: 2rem;
  width: 100%;
}

.pad-bottom-1 {
  padding-bottom: 1rem;
}

.splash .sp-hb {
  margin: 50px auto;
  width: 300px;
}

.splash .sp-bx-bt {
  background-color: #e1e4e7;
  padding: 30px;
}

.glyphicon-asterisk:before {
  content: "\002a";
}

@media (min-width: 768px) {
  .splash .sp-hb {
    margin: 50px auto;
    width: 400px;
  }
}

@media (min-width: 992px) {
  .splash .sp-hb {
    margin: 175px auto;
    width: 500px;
  }
}

.row:after,
.row:before {
  display: table;
  content: " ";
}

.icon_pulse {
  animation: circle 0.4s steps(8) infinite;
}

@keyframes circle {
  from {
    transform: rotate(90deg);
  }

  to {
    transform: rotate(450deg);
  }
}

.loading {
  font-size: 40px;
  text-align: center;
  width: 100%;
  color: #0b536a;
}

.buttonStyling {
  background-color: #0b536a;
  background-image: none;

  border-color: #dcdee1;
  border-style: outset;
  border-radius: 4px;
  border: 1px solid transparent;

  min-width: 150px;
  padding: 10px 14px;
  width: 100%;
  max-width: fit-content;
  height: auto;
  white-space: normal;
  touch-action: manipulation;
  cursor: pointer;

  color: #fff;
  font-weight: 700;
  font-size: 16px;
  font-family: Lato, sans-serif;
  line-height: 1.4375;

  margin: 0.2em;
}

.bottomNavSpacing {
  margin-top: 3rem;
}

.top-element-error {
  text-align: left;
  padding: 1rem 0rem;
}

@media (max-width: 600px) {
  .mainContainer {
    padding: 11px !important;
    margin-top: 10px;
  }

  .pageContainer {
    padding: 0px;
    border: none;
  }

  .buttonStyling {
    max-width: none;
  }

  .select-style {
    width: 80% !important;
  }

  .scheduleButton {
    max-width: none !important;
  }

  .timeContainer {
    border: none !important;
    padding: 0 !important;
  }

  .calendarWidth {
    margin-bottom: 3em;
  }

  .loading {
    padding-top: 3em;
  }
}

.step-time-container {
  /* width: "100%"; */
  background-color: #d3d3d3;
}
