.stepContainer {
  padding: 5vw;
  background: white;
}

.timeContainer {
  background: #ffffff;
  border: 1px #dddddd solid;
  padding: 32px 5px;
  min-height: 300px;
  margin: 1em;
  opacity: 1;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 6px 10px var(--designer-shadow);
  text-align: left;
}

.grid-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  list-style: none;
  text-align: center;
  width: 225px;
  height: 20px;
}
.col-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  list-style: none;
  text-align: center;
  width: 120px;
  height: 10px;
}

.stepThreeContainer {
  background: #ffffff;
  border: 1px #dddddd solid;
  padding: 32px 5px;
  margin: 1em;
  opacity: 1;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 6px 10px var(--designer-shadow);
  text-align: left;
}

.stepButtonContainer {
  text-align: left;
}

.greyBox {
  background: #8c8c8c;
  color: #8c8c8c;
  width: 30px;
  margin-right: 3px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.blueBox {
  background: #26374a;
  color: #26374a;
  width: 30px;
  border-radius: 5px;
  margin-left: 2px;
  margin-right: 3px;
  display: flex;
  align-items: center;
}

.selectPortContainer {
  background: #ffffff;
  border: 1px #dddddd solid;
  padding: 32px 5px;
  margin: 1em;
  opacity: 1;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 6px 10px var(--designer-shadow);
  text-align: left;
}

.startOverBtn {
  color: #335075;
  font: 16px Lato, sans-serif;
  background: #eaebed;
  margin: 0px 0px 0px 10px;

  min-width: 150px;
  padding: 10px 14px;
  width: 100%;
  max-width: fit-content;
  height: auto;
  white-space: normal;
  touch-action: manipulation;
  cursor: pointer;

  font-weight: 700;
  font-size: 16px;
  font-family: Lato, sans-serif;
  line-height: 1.4375;
  border-style: outset;
  border-color: #dcdee1;
  border-radius: 4px;
}

.buttonContainer {
  padding: 16px;
  display: flex;
  justify-content: space-between;
}

.modalContainer {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modalContent {
  background-color: #fefefe;
  margin: 20%;
  padding: 20px;
  height: 250px;
  width: 50vw;
  border-radius: 10px;
}

.currentSelectedTime {
  background: blue;
}

.contentContainer {
  display: flex;
}

.calendarWidth {
  width: 25%;
  padding-right: 0;
  margin-top: 35px;
}

.tableWidth {
  width: 5%;
}

.whiteButtonStyling {
  color: #335075;
  font: 16px Lato, sans-serif;
  background: #eaebed;
  margin: 0px 0px 0px 10px;
  min-width: 150px;
  padding: 10px 14px;
  width: 100%;
  max-width: -moz-fit-content;
  max-width: fit-content;
  height: auto;
  white-space: normal;
  touch-action: manipulation;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  font-family: Lato, sans-serif;
  line-height: 1.4375;
  border-style: outset;
  border-color: #dcdee1;
  border-radius: 4px;
}

.selectWorklocationStyle {
  width: 40%;
  padding: 10px;
  border-radius: 5px;
}

@media (max-width: 800px) {
  .contentContainer {
    display: block;
  }

  .selectWidth {
    width: auto;
  }

  .calendarWidth {
    width: auto;
  }
}

.bottomButtonContainer {
  display: flex;
  justify-content: right;
}

.schedule-txt-container {
  text-align: left;
}

.text-container {
  text-align: left;
}

.required-text {
  color: red;
  font-weight: bold;
}

.step-three-submit {
  padding-top: 2em;
}
