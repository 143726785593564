.title-en{
    color: rgba(0, 0, 0, 0.74);
    padding-top:1rem;
    padding-left: 2rem;
}
.title-fr{
    color: rgba(0, 0, 0, 0.74);
    padding-top:1rem;
    padding-right: 1rem;
}
.logo{
    padding-left: 1rem;
}
 .btn-home{
    padding-right: 1rem;
 }
