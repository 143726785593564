.viewAppointmentContainer {
  padding: 5vw;
  background: white;
}

.viewAppointmentTitleContainer {
  text-align: left;
  margin-bottom: 2em;
}

.viewAppointmentContentContainer {
  background: #ffffff;
  /*border: 1px #dddddd solid;*/
  padding: 32px 5px;
  /*margin: 1em;*/
  opacity: 1;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 6px 10px var(--designer-shadow);
}

.appointmentLocationContainer {
  background: #ffffff;
  border: 1px #dddddd solid;
  padding: 1rem;
  margin: 0.5em;
  opacity: 1;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 6px 10px var(--designer-shadow);
  text-align: left;
  width: 50%;
}

.appointmentApplicantContainer {
  background: #ffffff;
  border: 1px #dddddd solid;
  padding: 1rem;
  margin: 0.5em;
  opacity: 1;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 6px 10px var(--designer-shadow);
  text-align: left;
  width: 50%;
}

.viewApplicantTitle {
  margin-top: -10px;
}

.appointmentInfoContainer {
  display: flex;
  justify-content: space-around;
  width: auto;
}

.confirm-container {
  display: flex;
  align-items: self-start;
}

.check-svg {
  width: 3%;
  fill: green;
}

.info-svg {
  width: 4%;
  fill: rgb(78, 92, 214);
}

.mainContainer {
  text-align: left;
}

.reschedule-note {
  margin-left: 30px;
}

.confirm-padding {
  margin-top: 0.7em;
}

.please-note-padding {
  margin-top: 0.6em;
}

@media screen and (max-width: 880px) {
  #printContent {
    display: block;
  }

  .appointmentLocationContainer {
    width: auto;
  }

  .appointmentApplicantContainer {
    width: auto;
  }
}
