.timeContainer {
  background: #ffffff;
  border: 1px #dddddd solid;
  padding: 2rem;
  margin: 1em 0;
  opacity: 1;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 6px 10px var(--designer-shadow);
  text-align: left;
}

.col-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  list-style: none;
  background-color: lightgray;
  text-align: center;
  width: 100px;
  height: auto;
}

.booked {
  background: #8c8c8c;
  color: #335075;
  border-right: 1px solid black;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  position: relative;
  text-decoration: "strikethrough";
}

.bookingBtn {
  padding: 1px;
  margin: 1px;
  height: 40px;
  width: 200px;
}
.notBooked {
  background-color: #26374a;
  color: #ffffff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.notAvailable {
  background: #8c8c8c;
  color: #ffffff;
  font-weight: bold;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.rowButtons {
  background-color: #ffffff;
}

.rowLabel {
  justify-content: center;
  align-items: center;
  border: none;
  background-color: lightgray;
  border-right: 1px solid black;
  margin-right: 1rem;
  font-size: 20px;
}

.rowLabelHidden {
  justify-content: center;
  align-items: center;
  border: none;
  background-color: lightgray;
  color: lightgray;
  border-right: 1px solid black;
  margin-right: 1rem;
  font-size: 20px;
}

.time-table h1 {
  background-color: lightgray;
  border-bottom: none !important;
}
.time-row {
  border-bottom: 1px solid black;
}

.clearCol {
  background-color: ffffff;
  color: black;
  padding: 1px;
  margin: 1px;
  width: 100px;
  height: 40px;
  /*border-radius: 10px;*/
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.grid-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  list-style: none;
  text-align: center;
  width: 225px;
  height: 20px;
}

.stepThreeContainer {
  background: #ffffff;
  border: 1px #dddddd solid;
  padding: 32px 5px;
  margin: 1em;
  opacity: 1;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 6px 10px var(--designer-shadow);
  text-align: left;
}

.stepButtonContainer {
  text-align: left;
}

.greyBox {
  background: #8c8c8c;
  color: #8c8c8c;
  width: 30px;
  margin: 2px;
  margin-right: 3px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.blueBox {
  background: #26374a;
  color: #26374a;
  width: 30px;
  margin: 2px;
  border-radius: 5px;
  margin-left: 2px;
  margin-right: 3px;
  display: flex;
  align-items: center;
}

.selectPortContainer {
  background: #ffffff;
  border: 1px #dddddd solid;
  padding: 32px 5px;
  margin: 1em;
  opacity: 1;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 6px 10px var(--designer-shadow);
  text-align: left;
}

.startOverBtn {
  color: #335075;
  font: 16px Lato, sans-serif;
  background: #eaebed;
  margin: 0px 0px 0px 10px;

  min-width: 150px;
  padding: 10px 14px;
  width: 100%;
  max-width: fit-content;
  height: auto;
  white-space: normal;
  touch-action: manipulation;
  cursor: pointer;

  font-weight: 700;
  font-size: 16px;
  font-family: Lato, sans-serif;
  line-height: 1.4375;
  border-style: outset;
  border-color: #dcdee1;
  border-radius: 4px;
  margin-top: 2px;
}

.buttonContainer {
  padding: 16px;
  display: flex;
  justify-content: space-between;
}

.modalContainer {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modalContent {
  background-color: #fefefe;
  margin: 20%;
  padding: 20px;
  height: 250px;
  width: 50vw;
  border-radius: 10px;
}

.currentSelectedTime {
  background: rgb(0, 0, 255);
  
}

.contentContainer {
  display: flex;
  flex-direction: row;
  place-content: center;
}

.calendarWidth {
  width: 25%;
  padding-right: 0;
  margin-right: 1rem;
  margin-top: 35px;
  margin-top: -75px;
}

.tableWidth {
  width: 5%;
}

.buttonStyling:disabled,
.buttonStyling[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.whiteButtonStyling {
  color: #335075;
  font: 16px Lato, sans-serif;
  background: #eaebed;
  margin: 0px 0px 0px 10px;
  min-width: 150px;
  padding: 10px 14px;
  width: 100%;
  max-width: -moz-fit-content;
  max-width: fit-content;
  height: auto;
  white-space: normal;
  touch-action: manipulation;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  font-family: Lato, sans-serif;
  line-height: 1.4375;
  border-style: outset;
  border-color: #dcdee1;
  border-radius: 4px;
}

@media (max-width: 1400px) {
  .contentContainer {
    display: block;
  }

  .selectWidth {
    width: auto;
  }

  .calendarWidth {
    width: auto;
    margin-top: 0px;
  }

  .step-time-container {
    /* width: "100%"; */
    background-color: #d3d3d3;
  }
}

.notBooked:hover {
  background-color: white;
  color: #333333;
  font-weight: bold;
}

.notBooked:focus-visible {
  background-color: white;
  color: #333333;
  font-weight: bold;
}

.buttonStyling:hover {
  color: #fff;
  background-color: #205d93;
  border-color: #091c2d;
}

.react-calendar {
  margin: auto;
}

.wlocLabel {
  text-align: center;
  padding-bottom: 1rem;
}

:disabled {
  background: #fff;
  color: #ccc;
  cursor: no-drop;
}
.step-two-error {
  padding-right: 40%;
}
.tab-reverse {
  margin-right: 2rem;
}

.legend-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: end;
}
.bookingBtn:hover {
background-color: #1c578a;
  color: white !important;

}
.react-calendar__tile {
  color: #26374a;
  text-decoration-line: underline;
}



.react-calendar__tile:disabled {
  text-decoration-line: line-through;
  color: grey;
}

.react-calendar__tile--hasActive {
  background: #26374a;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #26374a;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1c578a
}
