.get-help-button {
  color: #335075;
  font: 16px Lato, sans-serif;
  background: #eaebed;
  margin: 0px 0px 0px 10px;
  min-width: 123px;
  padding: 10px 14px;
  width: 100%;
  max-width: -moz-fit-content;
  max-width: fit-content;
  height: auto;
  white-space: normal;
  touch-action: manipulation;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  font-family: Lato, sans-serif;
  line-height: 1.4375;
  border-style: outset;
  border-color: #dcdee1;
  border-radius: 4px;
  width: auto;

  float: right;
  margin-top: -20px;
  margin-right: 20px;
  display: flex;
}

.redirect-img {
  height: 19px;
  margin-left: 0.5em;
  fill: #335075;
}
