.homeScreenContainer {
  background-color: #ffffff;
  margin: 3rem;
  padding: 3rem;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.homeScreenTitleLabel {
  padding: 1rem;
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
}

.homeScreenDateLabel .homeScreenLocationLabel {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.homeScreenWlocSelect {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  width: 100%;
}

.homePageLoading {
  margin-top: 4rem !important;
}

.textContainer {
  text-align: left;
  width: 100%;
}

.bold {
  font-weight: bold;
}

.homeContainer {
  padding: 5vw;
  background: white;
}

.homeTitleContainer {
  text-align: left;
  margin-bottom: 2em;
}

.scheduleButton {
  background-color: #0b536a;
  background-image: none;

  border-color: #143c5f;
  border-style: outset;
  border-radius: 4px;
  border: 1px solid transparent;

  min-width: 50px;
  min-height: 50px;
  padding: 10px 14px;
  width: 100%;
  max-width: fit-content;
  height: auto;
  white-space: normal;
  touch-action: manipulation;
  cursor: pointer;

  color: #fff;
  font-weight: 700;
  font-size: 16px;
  font-family: Lato, sans-serif;
  line-height: 1.4375;
}

.home-text-container {
  text-align: left !important;
}

.session-exp-text {
  margin-left: 2em;
  padding-top: 14px;
}
