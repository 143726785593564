.confirmPage {
  text-align: left;
  padding: 5vw;
}

.successfulConfirmInformationContainerText {
  background: #ffffff;
  border: 1px #dddddd solid;
  opacity: 1;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 6px 10px var(--designer-shadow);
  margin: 2em;
  padding: 32px 5px;
}
