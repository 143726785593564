.cancelAppointmentContainer {
  background: #ffffff;
  border: 1px #dddddd solid;
  opacity: 1;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 6px 10px var(--designer-shadow);
  margin: 0;
  padding: 32px 5px;
}

.informationContainer {
  text-align: left;
}

.inputStyle {
  width: 400px;
}

.cancel-btn-container {
  display: flex;
  justify-content: space-between;
}
