.gcweb-menu {
  text-align: left !important;
}

.leftBlackMenu:hover {
  cursor: pointer;
}

.headerLngButton {
  background: none;
  border: none;
  cursor: pointer;
  color: #069;
  text-decoration: underline;
  padding: 0 !important;
}
