.modalContainer {
  background-color: rgb(0, 0, 0);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-main {
  position: fixed;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px #dddddd solid;
  opacity: 1;
  box-shadow: 0 6px 10px var(--designer-shadow);
  border-radius: 10px;
  background-color: #fefefe;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.cancelTitle {
  text-align: left;
  margin-left: 1em;
}

.cancelTextContainer {
  text-align: center;
}

.cancelButtonContainer {
  text-align: right;
  padding: 1em;
}

.cancelButtonStyle {
  color: #eaebed;
  font: 16px Lato, sans-serif;
  background: #335075;
  margin: 0px 0px 0px 10px;

  min-width: 150px;
  padding: 10px 14px;
  width: 100%;
  max-width: fit-content;
  height: auto;
  white-space: normal;
  touch-action: manipulation;
  cursor: pointer;

  font-weight: 700;
  font-size: 16px;
  font-family: Lato, sans-serif;
  line-height: 1.4375;
  border-style: outset;
  border-color: #dcdee1;
  border-radius: 4px;
}
